<template>
  <!-- 车辆例检记录 -->
  <div ref="trainInspectionRecord" class="trainInspectionRecord-page">
    <div class="form-box">
      <el-form :model="form" inline label-position="left" label-width="85px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="例检时间：">
              <el-date-picker
                v-model="examinationTime"
                :default-time="['00:00:00', '23:59:59']"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="例检员：">
              <el-autocomplete
                v-model.trim="form.inspector"
                :fetch-suggestions="inspectorSearchFun"
                :trigger-on-focus="false"
                clearable
                placeholder="请输入例检员姓名"
                @select="selectInspectorFun"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车牌号：">
              <el-autocomplete
                v-model.trim="form.licensePlateNumber"
                :fetch-suggestions="licensePlateNumberSearchFun"
                :trigger-on-focus="false"
                clearable
                placeholder="请输入车牌号"
                @select="selectLicensePlateNumberFun"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item label="例检结果：">
              <el-select v-model="form.inspectionResult">
                <el-option
                  v-for="role in inspectionResultOption"
                  :key="role.value"
                  :label="role.label"
                  :value="role.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="所属企业：">
              <companySelect
                clearable
                :company-tree="companyList"
                @getValue="getAddGroupId"
                @getName="getName"
              ></companySelect>
            </el-form-item>
            <el-form-item>
              <el-button
                v-if="listFind('查询')"
                type="primary"
                size="small"
                :loading="buttonLoading"
                @click="queryFun"
                >查询</el-button
              >
              <el-button
                v-if="listFind('导出')"
                :loading="buttonLoading"
                type="primary"
                size="small"
                @click="exportFun"
                >导出</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="table-box">
      <Table
        :table-data="tableData"
        :title-name="titleName"
        :operation="true"
        :table-height="tableHeight"
      >
        <template slot-scope="scope">
          <el-button
            v-if="listFind('查看')"
            type="text"
            size="small"
            @click="seeDetailsFun(scope.scopeRow.id)"
            >查看</el-button
          >
        </template>
      </Table>
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="sizeChangeFun"
        @current-change="currentChangeFun"
      ></el-pagination>
    </div>
    <detailsDialog ref="detailsDialogRef"></detailsDialog>
  </div>
</template>

<script>
import detailsDialog from "./trainInspectionDetails.vue";
import {
  getCompanyTree,
  queryRoutineInspectionRecordPageAPI,
  queryRoutineInspectionRecordInfoAPI,
  exportRoutineInspectionRecordAPI,
} from "@/api/lib/api.js";
export default {
  name: "",
  components: {
    detailsDialog,
  },
  props: {},
  data() {
    return {
      tableHeight: 0,
      buttonLoading: false,
      total: 0,
      examinationTime: "",
      exportParams: {},
      form: {
        inspectionStartTime: "",
        inspectionEndTime: "",
        licensePlateNumber: "",
        inspector: "",
        inspectionResult: null,
        companyId: "", //公司ID
        companyName: "", //公司名称
        pageSize: 10,
        currentPage: 1,
      },
      companyList: [],
      inspectionResultOption: [
        { label: "全部", value: null },
        { label: "合格", value: true },
        { label: "不合格", value: false },
      ],
      tableData: [],
      titleName: [
        {
          title: "例检时间",
          props: "inspectionTime",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "车属公司",
          props: "companyName",
        },
        {
          title: "例检员",
          props: "inspector",
        },
        {
          title: "例检结果",
          props: "inspectionResult",
          SpecialJudgment: (res) => {
            return res ? "合格 " : "不合格";
          },
        },
      ],
    };
  },
  filters: {},
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getCompanyList();
    this.getQueryRoutineInspectionRecordPage();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.trainInspectionRecord.clientHeight;
      this.tableHeight = wholeHeight - 320;
    },
    /**
     * @description 获取例检记录分页
     */
    getQueryRoutineInspectionRecordPage() {
      queryRoutineInspectionRecordPageAPI(this.form).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /**
     * @description 查询
     */
    queryFun() {
      if (this.examinationTime?.length) {
        this.form.inspectionStartTime = new Date(
          this.examinationTime[0]
        ).getTime();
        this.form.inspectionEndTime = new Date(
          this.examinationTime[1]
        ).getTime();
      } else {
        this.form.inspectionStartTime = "";
        this.form.inspectionEndTime = "";
      }
      this.exportParams = JSON.parse(JSON.stringify(this.form));
      this.form.currentPage = 1;
      this.getQueryRoutineInspectionRecordPage();
    },
    // 当前页码改变
    currentChangeFun(page) {
      this.form.currentPage = page;
      this.getQueryRoutineInspectionRecordPage();
    },
    // 当前条数改变
    sizeChangeFun(size) {
      this.form.pageSize = size;
      this.getQueryRoutineInspectionRecordPage();
      this.computeHeight();
    },
    /**
     * @description 导出
     */
    exportFun() {
      delete this.exportParams.pageSize;
      delete this.exportParams.currentPage;
      delete this.exportParams.companyName;
      exportRoutineInspectionRecordAPI(this.exportParams).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "车辆例检记录 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    /**
     * @description 列检员
     */
    inspectorSearchFun(val, callback) {
      let res = [];
      callback(res);
    },
    selectInspectorFun(val) {},
    /**
     * @description 车牌号
     */
    licensePlateNumberSearchFun(val, callback) {
      let res = [];
      callback(res);
    },
    selectLicensePlateNumberFun(val) {},
    /**
     * @description 获取企业树
     */
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 获取企业ID
    getAddGroupId(id) {
      this.form.companyId = id == null ? "" : id;
    },
    // 获取企业名称
    getName(name) {
      this.form.companyName = name;
    },
    /**
     * @description 查看详情
     */
    seeDetailsFun(id) {
      queryRoutineInspectionRecordInfoAPI({ id }).then((res) => {
        if (res.code === "SUCCESS") {
          this.$refs.detailsDialogRef.openDialogFun(res.data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.trainInspectionRecord-page {
  height: 100%;
  width: 100%;
  .form-box {
    background-color: #ffffff;
    padding: 20px 0 0 20px;
    ::v-deep .el-range-separator {
      line-height: 26px;
    }
  }
  .table-box {
    margin-top: 20px;
    background-color: #ffffff;
    padding: 20px;
  }
}
</style>
