var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "trainInspectionRecord", staticClass: "trainInspectionRecord-page" },
    [
      _c(
        "div",
        { staticClass: "form-box" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "left",
                "label-width": "85px",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "例检时间：" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "default-time": ["00:00:00", "23:59:59"],
                              type: "datetimerange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.examinationTime,
                              callback: function ($$v) {
                                _vm.examinationTime = $$v
                              },
                              expression: "examinationTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "例检员：" } },
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              "fetch-suggestions": _vm.inspectorSearchFun,
                              "trigger-on-focus": false,
                              clearable: "",
                              placeholder: "请输入例检员姓名",
                            },
                            on: { select: _vm.selectInspectorFun },
                            model: {
                              value: _vm.form.inspector,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "inspector",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.inspector",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号：" } },
                        [
                          _c("el-autocomplete", {
                            attrs: {
                              "fetch-suggestions":
                                _vm.licensePlateNumberSearchFun,
                              "trigger-on-focus": false,
                              clearable: "",
                              placeholder: "请输入车牌号",
                            },
                            on: { select: _vm.selectLicensePlateNumberFun },
                            model: {
                              value: _vm.form.licensePlateNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "licensePlateNumber",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.licensePlateNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "例检结果：" } },
                        [
                          _c(
                            "el-select",
                            {
                              model: {
                                value: _vm.form.inspectionResult,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "inspectionResult", $$v)
                                },
                                expression: "form.inspectionResult",
                              },
                            },
                            _vm._l(_vm.inspectionResultOption, function (role) {
                              return _c("el-option", {
                                key: role.value,
                                attrs: { label: role.label, value: role.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属企业：" } },
                        [
                          _c("companySelect", {
                            attrs: {
                              clearable: "",
                              "company-tree": _vm.companyList,
                            },
                            on: {
                              getValue: _vm.getAddGroupId,
                              getName: _vm.getName,
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _vm.listFind("查询")
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    loading: _vm.buttonLoading,
                                  },
                                  on: { click: _vm.queryFun },
                                },
                                [_vm._v("查询")]
                              )
                            : _vm._e(),
                          _vm.listFind("导出")
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    loading: _vm.buttonLoading,
                                    type: "primary",
                                    size: "small",
                                  },
                                  on: { click: _vm.exportFun },
                                },
                                [_vm._v("导出")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-box" },
        [
          _c("Table", {
            attrs: {
              "table-data": _vm.tableData,
              "title-name": _vm.titleName,
              operation: true,
              "table-height": _vm.tableHeight,
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.listFind("查看")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.seeDetailsFun(scope.scopeRow.id)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage,
            },
            on: {
              "size-change": _vm.sizeChangeFun,
              "current-change": _vm.currentChangeFun,
            },
          }),
        ],
        1
      ),
      _c("detailsDialog", { ref: "detailsDialogRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }