<template>
  <div class="trainInspectionDetails-page">
    <el-dialog
      title="例检详情"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <div>
            <span class="label">例检时间：</span>
            <span>{{ detailsInfo.inspectionTime }}</span>
          </div>
          <div>
            <span class="label">车牌号：</span>
            <span>{{ detailsInfo.licensePlateNumber }}</span>
          </div>
          <div>
            <span class="label">例检结果：</span>
            <span>{{ detailsInfo.inspectionResult ? "合格" : "不合格" }}</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div>
            <span class="label">例检员：</span>
            <span>{{ detailsInfo.inspector }}</span>
          </div>
          <div>
            <span class="label">车属公司：</span>
            <span>{{ detailsInfo.companyName }}</span>
          </div>
        </el-col>
      </el-row>
      <template>
        <!-- 合格/不合格 -->
        <div v-if="isShowInspectionResult" style="display: flex">
          <span v-if="!detailsInfo.inspectionResult" class="label">{{
            detailsInfo.inspectionResult ? "合格项：" : "不合格项："
          }}</span>
          <div>
            <div
              v-for="(item, index) in detailsInfo.recordContent"
              :key="index"
              style="display: flex"
            >
              <span class="label label-tip">{{ item.inspectionContent }}</span>
              <div>
                <div
                  v-for="(child, i) in item.inspectionChildContent"
                  :key="i"
                  style="margin-bottom: 10px"
                >
                  {{ child.childContent }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 查看大图 -->
        <div v-else class="demo-image__preview">
          <el-image
            v-for="(item, index) in urlList"
            :key="index"
            :src="item"
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import host from "@/api/static/host.js";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      isShowInspectionResult: true,
      dialogVisible: false,
      detailsInfo: {},
      urlList: [],
      srcList: [],
    };
  },
  filters: {},
  computed: {},
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.detailsInfo = {};
        this.urlList = [];
        this.srcList = [];
      }
    },
  },
  created() {},
  mounted() {},
  methods: {
    openDialogFun(info) {
      this.isShowInspectionResult = !!info.inspectionWay;
      this.detailsInfo = info;
      if (info.inspectionFile.length && info.inspectionWay == 0) {
        info.inspectionFile.forEach((element) => {
          this.urlList.push(host.imgURL + element);
          this.srcList.push(host.imgURL + element);
        });
      }
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.trainInspectionDetails-page {
  .el-col {
    div {
      display: flex;
      margin-bottom: 40px;
    }
  }
  .label {
    display: inline-block;
    width: 80px;
  }
  .label-tip {
    width: 180px;
    margin-right: 20px;
    font-weight: 600;
  }
  .demo-image__preview {
    padding-left: 80px;
    .el-image {
      width: 100px;
      height: 100px;
      margin-right: 5px;
    }
  }
}
</style>
