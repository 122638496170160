var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "trainInspectionDetails-page" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "例检详情",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", [
                  _c("span", { staticClass: "label" }, [_vm._v("例检时间：")]),
                  _c("span", [_vm._v(_vm._s(_vm.detailsInfo.inspectionTime))]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "label" }, [_vm._v("车牌号：")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.detailsInfo.licensePlateNumber)),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "label" }, [_vm._v("例检结果：")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.detailsInfo.inspectionResult ? "合格" : "不合格"
                      )
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", [
                  _c("span", { staticClass: "label" }, [_vm._v("例检员：")]),
                  _c("span", [_vm._v(_vm._s(_vm.detailsInfo.inspector))]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "label" }, [_vm._v("车属公司：")]),
                  _c("span", [_vm._v(_vm._s(_vm.detailsInfo.companyName))]),
                ]),
              ]),
            ],
            1
          ),
          [
            _vm.isShowInspectionResult
              ? _c("div", { staticStyle: { display: "flex" } }, [
                  !_vm.detailsInfo.inspectionResult
                    ? _c("span", { staticClass: "label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.detailsInfo.inspectionResult
                              ? "合格项："
                              : "不合格项："
                          )
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    _vm._l(
                      _vm.detailsInfo.recordContent,
                      function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticStyle: { display: "flex" } },
                          [
                            _c("span", { staticClass: "label label-tip" }, [
                              _vm._v(_vm._s(item.inspectionContent)),
                            ]),
                            _c(
                              "div",
                              _vm._l(
                                item.inspectionChildContent,
                                function (child, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      staticStyle: { "margin-bottom": "10px" },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(child.childContent) + " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ])
              : _c(
                  "div",
                  { staticClass: "demo-image__preview" },
                  _vm._l(_vm.urlList, function (item, index) {
                    return _c("el-image", {
                      key: index,
                      attrs: { src: item, "preview-src-list": _vm.srcList },
                    })
                  }),
                  1
                ),
          ],
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }